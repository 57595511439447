.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 624px;
  margin: 0 auto;
  padding: 36px 12px 100px 12px;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    padding: 72px 0;
  }
}

.header {
  margin-bottom: 24px;
  width: 100%;

  .top {
    display: flex;
    align-items: center;
    column-gap: 24px;
    margin-bottom: 24px;

    :global(.m-button--black) {
      width: 42px;
      height: 42px;
      padding: 12px;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.storiesWrapper {
  max-width: 624px;
  max-height: 682px;
  background-color: var(--grey200);
  padding: 0px 12px 16px 12px;
  overflow: scroll;
  position: relative;
  transition: background-color 0.1s ease-in;

  @media screen and (min-width: 768px) {
    width: 100%;
  }
}

.dropdownContainer {
  margin: 0 16px;
  position: relative;
}

.dropdown {
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: calc(100% + 8px);
    min-width: 161px;
    width: auto;
    height: inherit;
    bottom: inherit;
    left: inherit;
    transform: inherit;
    background-color: inherit;
  }
}

.dropdownButton {
  svg {
    transform: rotate(90deg);
    width: 18px;
    height: 18px;
  }
}

.filterButton {
  position: relative;
}

.right {
  position: relative;
  min-height: 400px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 64px;
    height: 64px;
  }
}

.filterActive {
  padding-right: 42px;
}

.resetFilter {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;

  svg {
    width: 18px;
    height: 18px;
    fill: var(--grey400);

    &:hover {
      fill: var(--grey600);
    }
  }
}