.container {
    width: 100%;
    padding: 0 24px 24px 24px;
}

.filtersWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    div {
        cursor: pointer;
    }

    button {
        display: flex;
        align-items: center;
    }
}

// .filter {
//     display: flex;
//     align-items: center;
//     gap: 8px;
//     border-radius: 100px;
//     background-color: black;
//     padding: 14px;

//     .tag {
//         color: #fff;
//         font-weight: bolder;
//     }
// }