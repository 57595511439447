.button {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 12px 16px;
  border-radius: 100px;
  background-color: #25e197;
}

.input {
  width: 50%;
}

.date {
  position: relative;
  margin-top: 32px;
  padding: 16px;
  background-color: var(--grey100);
  border-radius: 8px;
  margin-bottom: 32px;
}

.dateButton {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
  }
}

.selectDate {
  margin-top: 24px;
  display: flex;
  align-items: center;
  background-color: var(--grey200);
  padding-left: 8px;
  border-radius: 100px;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-left: 16px;

    width: inherit;
  }

  svg {
    margin: 0 8px;
    width: 18px;
    height: 18px;
    color: #000;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 0px;
    border-radius: 100px;

    @media screen and (min-width: 768px) {
      padding: 12px 16px;
    }
  }
}

.endDate:hover,
.startDate:hover {
  background-color: var(--grey300);
}

.calendarPopup {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: 1024px) {
    left: 0;
    transform: initial;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
