.userDropdownItem {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 8px;
    border-radius: 6px;
    width: 100%;
    &:hover {
        background-color: var(--grey100);
    }
    p {
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        color: #000;
    }
}

.picture {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    svg {
        font-size: 12px;
        color: #000;
        min-width: 12px;
    }
}
