.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: #fff;
  text-align: right;
}

.cardRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .accountName {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #000;
    width: 50%;
  }

  .rowName {
    font-size: 12px;
    font-weight: 500;
  }

  .identifier {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #646464;
  }

  .password {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #646464;
  }

  .project {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #646464;
    width: 50%;
  }

  .endButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 10%;
    column-gap: 16px;
  }

  .link,
  .editLink {
    padding: 0 4px;
    display: flex;
    svg {
      font-size: 18px;
      color: #000;
    }
    &:hover {
      svg {
        color: var(--grey500);
      }
    }
  }
}
