.wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  padding: 24px;
  overflow-y: auto;
  transition: background-color 0.2s ease;

  @media screen and (min-width: 768px) {
    max-width: 528px;
    padding: 48px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  row-gap: 8px;

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
    row-gap: 0;
  }
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 13px;
}

.name {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  width: 100%;
  margin-bottom: 32px;

  &::placeholder {
    color: #000;
    opacity: 0.3;
  }
}

.inputs {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}