.main {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 99;
    @media screen and (max-width: 1024px) {
        &:after {
            position: absolute;
            content: '';
            background-color: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
   

    @media screen and (min-width: 1024px) {
        right: 100%;
    }
}

.modal {
    @extend .main;

    @media screen and (min-width: 1024px) {
        height: 100%;
        max-width: 528px;
        right: 0;
    }
   
}

.wrapper {

    padding: 0 0 24px;
    overflow-y: auto;
    bottom: 0;
    right: 0;
    background-color: var(--grey100);
    z-index: 99;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    overflow: auto;

    @media screen and (min-width: 1024px) {
        max-height: inherit;
        padding: 32px 16px;
    }
}