.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 64px;
    height: 64px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #000;
  position: relative;
  padding: 16px 24px 24px 24px;
  @media print {
    padding: 22px;
  }
  @media screen and (min-width: 768px) {
    padding: 22px 84px;
    flex-direction: row;
  }
}

.title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.infos {
  text-align: center;
  margin-bottom: 16px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    text-align: left;
    max-width: 45%;
  }
}

.infos p {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px; /* 184.615% */
  opacity: 0.7;
  svg {
    width: 12px;
    height: 12px;
  }
}

.logo {
  position: inherit;
  @media print {
    right: 22px;
    left: auto;
    transform: translate(0, -50%);
  }
  svg {
    width: 56px;
    height: 64px;
  }
  @media screen and (min-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  @media print {
    display: none;
  }
}

.mainWrapper {
  padding: 24px;
  @media print {
    padding: 64px 10px;
  }
  @media screen and (min-width: 1024px) {
    padding: 64px 84px 64px 84px;
  }
}

.subtitle {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px; /* 145.455% */
  margin-bottom: 24px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 64px;
    height: 64px;
  }
}

.time {
  margin-bottom: 32px;
}

.timeTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #646464;
  margin-bottom: 12px;
}

.timeNumbers {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #000;
  }
}

.red {
  background-color: var(--red);
  border-radius: 100px;
  padding: 4px 8px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
}

.missionsList {
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
  margin-bottom: 40px;
  .missionsItem {
    width: 100%;
    padding: 0 12px;
    margin-bottom: 24px;
    @media print {
      width: 50%;
    }
    @media screen and (min-width: 768px) {
      width: calc(100% / 2);
    }
    @media screen and (min-width: 1024px) {
      width: calc(100% / 3);
    }
  }
}

.noMission {
  color: #646464;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 96px;
}

.timersTitle {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px; /* 145.455% */
  margin-bottom: 32px;
}

.historyList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.noTimer {
  margin-top: 96px;
  color: #646464;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
