.m-input {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  height: 48px;
  padding: 0px 16px;
  width: 100%;
  border: 1px solid var(--grey300);
  border-radius: 100px;
  color: var(--grey800);

  &--dark {
    background-color: var(--grey700);
    color: #fff;

    &::placeholder {
      color: var(--grey400);
    }
    &:focus {
      border-color: #fff;
    }
    &:disabled {
      background-color: var(--grey800);
      cursor: not-allowed;
      &::placeholder {
        color: var(--grey600);
      }
      &:hover {
        background-color: var(--grey800);
      }
    }
    &:hover {
      background-color: var(--grey800);
    }
    :-webkit-input-autofill {
      background-color: var(--grey700) !important;
    }
  }

  &--grey {
    border: 1px solid var(--grey300);
    background-color: var(--grey100);
    color: var(--grey600);
    &::placeholder {
      color: var(--grey400);
    }
    &:focus {
      border-color: #000;
    }
    &:disabled {
      background-color: var(--grey100);
      cursor: not-allowed;
      color: var(--grey500);
      &::placeholder {
        color: var(--grey400);
      }
      &:hover {
        background-color: var(--grey100);
      }
    }
    &:hover {
      background-color: var(--grey300);
    }
  }

  &--error {
    border-color: var(--red);
  }

  &--border {
    border: 1px solid #d4d9e8;
    border-radius: 100px;
  }
}
