.wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1024px) {
    width: 100vw;
    height: 100vh;
    left: 0;
    transform: translateX(0);
  }
}

.popup {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.16);
  animation: slideInFromBottom 0.3s ease forwards;

  @media screen and (min-width: 1024px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: relative;
    width: 515px;
    box-shadow: inherit;
    animation: none;
  }
}

@keyframes slideInFromBottom {
  0% {
    bottom: -50px;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}