.list {
    position: relative;
    min-width: 322px;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 16px;

    @media screen and (min-width: 1024px) {
        margin-right: inherit;
    }
}

.right {
    position: relative;
    padding: 16px 12px;
    width: 100%;
    height: 100%;
    background-color: var(--grey200);
}

.add {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.single {
    width: 100%;
}