.wrapper {
  position: fixed;
  padding: 16px;
  z-index: 99999;
  bottom: 0px;
  left: 0;
  width: 100vw;
  background-color: #ffffff;
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  animation: slideInFromBottom 0.3s ease forwards;

  @media screen and (min-width: 1024px) {
    animation: none;
    position: relative;
    bottom: inherit;
    width: auto;
    border-radius: 16px;
  }
}

.noColor {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.noPadding {
  padding: 0;
}

@keyframes slideInFromBottom {
  0% {
    bottom: -50px;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}