.m-person-tag {
    display: flex;
    align-items: center;
    padding: 5px 20px 5px 5px;
    background-color: var(--grey100);
    border-radius: 100px;
    width: fit-content;
    &__img {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        margin-right: 12px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #000;
    }
}
