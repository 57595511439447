.wrapper {
    height: 100vh;
    padding: 40px 0;
    position: relative;
}

.title {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    color: #000000;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 80px - 42px);
}
