.wrapper {
    height: 100vh;
    width: 528px;
    position: relative;
    padding: 40px;
    overflow-y: auto;
    background-color: #fff;
}

.title {
    color: #000;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 60px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.infos {
    label {
        color: #000;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 8px;
        display: block;
    }
    p {
        color: #646464;
        font-size: 13px;
        font-weight: 500;
    }
}

.copy {
    margin-top: 24px;
    background-color: var(--grey200);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    padding: 12px 16px;
    width: 100%;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    svg {
        width: 16px;
        height: 16px;
    }
}

.linkUrl {
    color: var(--grey800);
    font-size: 14px;
    font-weight: 500;
    max-width: 330px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
