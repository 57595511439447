.wrapper {
  height: 70vh;
  width: 100vw;
  position: relative;
  bottom: -157px;
  padding: 24px 24px 32px 24px;
  background-color: #fff;
  border-radius: 16px 16px 0px 0px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    overflow: visible;
    bottom: inherit;
    height: 100vh;
    padding: 48px;
    border-radius: 0;
    width: 528px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -24px;
  margin-bottom: 48px;
  padding: 24px;
  border-radius: 16px 16px 0px 0px;

  background: var(--grey100);

  :global(.m-button--ratio) {
    width: 48px;
    height: 48px;
    padding: 0;
    border-radius: 50%;
  }
  @media screen and (min-width: 768px) {
    background-color: #fff;
    padding: 0;
    margin: 0;
    margin-bottom: 48px;
    border-radius: 0;
  }
}

.title {
  color: #000;
  font-size: 15px;
  font-weight: 700;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 100px;
  background-color: #000;
  p {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
  }
  svg {
    width: 18px;
    height: 18px;
    color: #fff;
  }
}

.titleDate {
  color: #000;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
}

.subtitleDate {
  color: #646464;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 24px;
}

.titleMissions {
  color: #000;
  font-size: 13px;
  font-weight: 700;
  margin: 48px 0 16px 0;
}

.missions {
  overflow-y: auto;
  max-height: calc(100vh - 380px);
  margin-bottom: 24px;
  li:not(:last-child) {
    margin-bottom: 16px;
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
}
