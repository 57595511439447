.headerContainer {
    padding: 24px 24px 0px 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    @media screen and (min-width: 1024px) {
        padding: 0 48px 0 24px;
    }
}

.title {
    color: #fff;
}

.subpages {
    display: flex;
    margin-top: 32px;
    justify-content: space-around;
    gap: 56px;

    @media screen and (min-width: 1024px) {
        margin-top: 0;
        gap: 24px;
        justify-content: start;
    }
}

.activePage {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    position: relative;
    padding: 12px 16px;

    &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        width: calc(100% + 16px);
        height: 4px;
        background-color: #fff;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 30px 30px 0 0;
    }
}

.inactivePage {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    opacity: 0.5;
    padding: 12px 16px;

    &:hover {
        opacity: 1;
    }
}

.container {
    padding: 42px 0;
    max-width: 624px;
    margin: 0 auto;
}

.headWrapper {
    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0 24px 16px;
    }
}

.dropdownContent {
    display: flex;
    margin-top: 24px;
    // min-width: 366px;
    // max-width: 542px;
    align-items: center;
    align-content: center;
    gap: 16px;
    // flex: 1 0 0;
    flex-direction: column;

    @media screen and (min-width: 590px) {
        flex-direction: row;
    }

    @media screen and (min-width: 1024px) {
        margin-top: 0;
    }
}

.calendarDropdown {
    position: relative;
    width: 100%;

    @media screen and (min-width: 590px) {
        width: auto;
    }
}

.date {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    padding-left: 16px;
    background-color: #3b3a3e;
    border-radius: 100px;
    justify-content: center;
}

.noDate {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    padding-left: 16px;
    background-color: #3b3a3e;
    border-radius: 100px;
    position: relative;
    z-index: 1;

    &::after {
        background-color: inherit;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        content: "";
        display: block;
        position: absolute;
        border-radius: 100px;
        transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    &:hover {
        &::after {
            transform: scale(1.1);
        }
    }
}

.selectDate {
    display: flex;
    align-items: center;
    transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    position: relative;
    z-index: 2;

    svg {
        margin: 0 8px;
        width: 18px;
        height: 18px;
        color: #fff;
        fill: #fff;
    }

    p {
        transition: background-color 0.1s ease-in;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 16px;
        border-radius: 100px;
        color: #fff;
    }
}

.resetFilters {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 100px;
    transition: background-color 0.1s ease-in;
    margin-left: 8px;

    &:hover {
        background-color: #5b5a5d;
    }

    svg {
        color: #fff;
        width: 18px;
        height: 18px;
    }
}

.endDate:hover,
.startDate:hover {
    background-color: #5b5a5d;
}

.calendarDropdownContent {
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;

    @media screen and (min-width: 1024px) {
        position: absolute;
        top: calc(100% + 8px);
        z-index: 12;
        right: 0;
        width: auto;
        height: inherit;
        bottom: inherit;
        left: inherit;
        transform: inherit;
        background-color: inherit;
    }
}

.resetMissionFilter {
    display: flex;
    position: absolute;
    font-size: 18px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: var(--grey400);
    z-index: 2;
}

.selectPerson {
    border-radius: 100px;
    background-color: #3b3a3e;
    padding: 0 16px;
    display: flex;
    height: 42px;
    align-items: center;
    transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    position: relative;
    z-index: 2;

    svg {
        margin-right: 8px;
        width: 18px;
        height: 18px;
        color: #fff;
        fill: #fff;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        border-radius: 100px;
        color: #fff;
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100px;
        z-index: -1;
        transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
        background-color: #3b3a3e;
    }

    &:hover {
        &::after {
            transform: scale(1.1);
        }
    }
}

.resetMissionFilter {
    display: flex;
    position: absolute;
    font-size: 18px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: var(--grey200);
    z-index: 2;
}

.personsDropdown {
    position: relative;
    width: 100%;

    @media screen and (min-width: 590px) {
        width: auto;
    }
}

.persons {
    width: 100%;

    @media screen and (min-width: 590px) {
        width: auto;
    }

    .selectPerson {
        width: 100%;
        justify-content: center;

        @media screen and (min-width: 590px) {
            width: auto;
        }
    }
}

.personsDropdownContent {
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;

    @media screen and (min-width: 1024px) {
        position: absolute;
        top: calc(100% + 8px);
        z-index: 12;
        right: 0;
        width: auto;
        height: inherit;
        bottom: inherit;
        left: inherit;
        transform: inherit;
        background-color: inherit;
    }
}