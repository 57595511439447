.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }
}

.overflow {
  overflow-x: auto;
  overflow-y: hidden;
}

.contactsHead {
  min-width: 1000px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #000;
  margin-bottom: 8px;
}

.nameHead {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
  width: 25%;
  max-width: 25%;
  flex-shrink: 0;
}

.capacityHead {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
  width: 35%;
  max-width: 35%;
  flex-shrink: 0;
}

.mailHead {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
  width: 20%;
}

.phoneHead {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
  width: 20%;
}

.noLogs {
  color: #646464;
  font-size: 14px;
  line-height: 24px;
  margin-top: 36px;
  text-align: center;
}

.list {
  display: flex;

  flex-direction: column;
  gap: 16px;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 24px;
    li {
      width: calc(100% / 12 * 4);
    }
  }
}
