.container {
  max-height: 250px;
  overflow-y: auto;
}

.missionDropdownItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 8px;
  border-radius: 6px;
  max-width: 100%;
  width: 100%;
  &:hover {
    background-color: var(--grey100);
    width: 100%;
  }
  span {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    flex-shrink: 0;
  }
  div {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  p {
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
