.title {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: var(--grey900);
    margin-bottom: 32px;
}

.container {
    max-width: 328px;
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
}

.small {
    padding: 0;
}

.calendarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
    p {
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        color: var(--grey900);
    }
}

.decreaseMonth,
.increaseMonth {
    display: flex;
    padding: 7px;
    transition: 0.2s all ease;
    border-radius: 100px;
    &:hover {
        background-color: var(--grey100);
    }
    svg {
        font-size: 10px;
        color: #646464;
    }
}
