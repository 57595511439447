.container {
    padding: 8px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    :global(.m-button) {
        width: 100%;
        justify-content: center;
        margin-top: 24px;
    }
}

.hours {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.hourTitle {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 8px;
}

.inputs {
    display: flex;
    align-items: center;
    gap: 6px;
    input {
        text-align: center;
        padding: 12px 0;
        width: 47px;
        background-color: var(--grey200);
        color: var(--grey800);
        font-size: 14px;
        font-weight: 500;
    }
}

.begin {
    .inputs {
        input:first-child {
            border-radius: 100px 0 0 100px;
        }
        input:last-child {
            border-radius: 0 100px 100px 0;
        }
    }
}

.end {
    .inputs {
        input:first-child {
            border-radius: 100px 0 0 100px;
        }
        input:last-child {
            border-radius: 0 100px 100px 0;
        }
    }
}

.arrow {
    margin-top: 22px;
    width: 18px;
    height: 18px;
}
