.container {
    display: flex;
    position: relative;
    width: fit-content;
}

.input {
    cursor: pointer;
    width: 68px;
    height: 42px;
    border-radius: 100px;
    background-color: var(--grey300);
    transition: all 0.1s ease-in-out;
    &:checked {
        background-color: var(--light-green);
    }
}

.circle {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
}

.checked {
    left: 34px;
    background-color: #fff;
}

.unchecked {
    left: 8px;
    background-color: #000;
}
