.m-radio {
    position: relative;
    input {
        position: absolute;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        &:checked ~ .checkmark {
            background-color: #000;
            border: none;
            svg {
                display: block;
            }
            &--border {
                border: 2px solid #000;
            }
        }
    }
    .checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 32px;
        flex-shrink: 0;
        background-color: var(--grey200);
        svg {
            font-size: 18px;
            display: none;
            color: #fff;
        }
        &--big {
            width: 32px;
            height: 32px;
        }
        &--black {
            background-color: #000;
            svg {
                color: #000;
            }
        }
    }
}
