.missionsList {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  li {
    max-width: 100%;
  }
}

.mission {
  padding: 4px 8px;
  border-radius: 100px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100%;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selectedMission {
  border: 2px solid #000;
}
