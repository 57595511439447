.container {
  position: relative;
  display: flex;
  gap: 8px;
  padding: 16px;
  background-color: #fff;
  align-items: center;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 1150px) {
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.greyContainer {
  background-color: #f1f4f9;
}

.input {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #000;
  max-width: 250px;

  &::placeholder {
    color: #6e6e6e;
  }

  &.inputFill {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.rightContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 1150px) {
    max-width: inherit;
    justify-content: flex-end;
  }
}

.nameContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;

  @media screen and (min-width: 600px) {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    max-width: 300px;
  }

  @media screen and (min-width: 1150px) {
    max-width: 450px;
  }
}

.subtaskContainer {
  margin-right: 16px;
  width: 35%;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  width: 70%;

  @media screen and (min-width: 600px) {
    width: 100%;
  }
}

.tagMission {
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  height: 21px;
  min-width: 21px;
  padding: 4px 8px;

  p {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #000;
    display: -webkit-box;
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.addMissionContainer {
  position: relative;
}

.addMissionButton {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.addMissionDropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 312px;
}

.addCustomer,
.addMission {
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 8px;
  background-color: var(--grey100);
  border-radius: 100px;
  white-space: nowrap;
  height: 21px;

  svg {
    width: 13px;
    height: 13px;
    color: var(--grey400);
    margin-right: 4px;
  }

  p {
    color: var(--grey600);
    font-size: 10px;
    font-weight: 600;
  }

  &:hover {
    background-color: var(--grey200);
  }
}

.button {
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: var(--grey200);
  }

  svg {
    width: 18px;
    height: 18px;
    color: var(--grey600);
  }

  @media screen and (min-width: 768px) {
    padding: 8px 12px;
  }
}

.time {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
}

.dateTimer {
  width: 150px;
}

.dateTimerGrey {
  margin: 0 92px 0 auto;
}

.duration {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  // flex-shrink: 0;
}

.durationGrey {
  margin-right: 32px;
}

.options {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;

  &__align {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 8px;
  }

  @media screen and (min-width: 1150px) {
    position: inherit;
    transform: translateY(0);
    margin-left: 32px;
  }
}

.optionsDropdownWrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  @media screen and (min-width: 1024px) {
    position: inherit;
    width: auto;
    height: inherit;
    bottom: inherit;
    left: inherit;
    transform: inherit;
    background-color: inherit;
    z-index: inherit;
  }
}

.optionsDropdown {
  position: fixed;
  padding: 16px;
  z-index: 4;
  bottom: 0;
  left: 50%;
  width: 100vw;
  background-color: #ffffff;
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
  transform: translateX(-50%);
  animation: slideInFromBottom 0.3s ease forwards;

  @media screen and (min-width: 1024px) {
    position: absolute;
    bottom: 80%;
    right: 5%;
    width: 229px;
    border-radius: 16px;
    left: inherit;
    transform: none;
    top: inherit;
    animation: none;
  }
}

.optionsButton {
  padding: 4px;
  display: flex;

  svg {
    width: 18px;
    height: 18px;
    color: var(--grey400);
    fill: var(--grey400);
  }

  &:hover {
    svg {
      color: var(--grey600);
      fill: var(--grey600);
    }
  }
}

.actionItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;

  height: 40px;

  &:hover {
    background-color: var(--grey100);
  }

  svg {
    min-width: 17px;
    min-height: 17px;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.play {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #000;
  position: relative;
  z-index: 1;
  margin-right: 0px;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: transparent;
    background-color: #000;
  }

  &:hover {
    &::after {
      transform: scale(1.1);
    }
  }

  svg {
    width: 12px;
    height: 12px;
    color: #fff;
  }
}

.confirmationDelete {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 8px;

  button {
    width: 100%;
    justify-content: center;
  }
}

@keyframes slideInFromBottom {
  0% {
    bottom: -50px;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}