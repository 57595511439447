.m-select {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  color: var(--grey800);

  select {
    width: 100%;
    height: 48px;
    padding: 0px 16px;
    border-radius: 100px;
    border: 1px solid var(--grey300);

    &:focus {
      background-color: var(--grey200);
    }
  }

  &__arrow {
    position: absolute;
    right: 16px;

    line-height: 0;
  }

  &--error {
    border-color: var(--red);
  }

  &--border {
    border: 1px solid #d4d9e8;
    border-radius: 100px;
  }
}