.input {
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    color: #fff;
    margin-right: 124px;
    &::placeholder {
        color: #6e6e6e;
    }
}

.selectedCustomer {
    margin-right: 124px;
}

.container {
    position: absolute;
    bottom: 100%;
    right: 0;
    width: calc(100% - 32px);
    background-color: var(--grey800);
    border-radius: 14px 14px 0 0;
}

.suggestionsContainer {
    padding: 24px;
    display: flex;
    overflow-x: auto;
}

.suggestions {
    color: #a7a7a7;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-right: 8px;
    line-height: 21px;
}

.tags {
    display: flex;
    align-items: center;
    gap: 8px;
}
