.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 80px;
  padding: 16px 24px;
  gap: 12px 24px;
  flex-wrap: wrap;
  background-color: #fff;

  @media screen and (min-width: 1024px) {
    border-left: 6px;
    padding: 22px 32px 22px 24px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }
}

.name {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  @media screen and (min-width: 1024px) {
    width: auto;
  }
}

.customer {
  color: #646464;
  font-size: 14px;
  font-weight: 500;
}

.time {
  grid-column: 3;
}

.timeText {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }
}
