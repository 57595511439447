.subtitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    color: #000;
    margin-bottom: 24px;
}

.wrapper {
    background-color: #fff;
    padding: 0;
    max-width: 624px;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        padding: 32px 64px 40px 64px;
    }
}

.profilForm {
    width: 100%;
}

.picture {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 40px;
}

.pictureImg {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.pictureSvg {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: var(--grey300);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 48px;
    }
}

.file {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    svg {
        font-size: 18px;
        color: #000;
    }

    p {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        font-size: 0;
        z-index: 2;

        &::file-selector-button {
            font-size: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            margin: 0;
            padding: 0;
            font-size: 0;
            height: 100%;
            width: 100%;
            border: none;
            background-color: transparent;
        }
    }
}

.infoFormat {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    color: var(--grey600);
    margin-bottom: 8px;
}

.infoWeight {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    color: var(--grey600);
}

.inputFlex {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    gap: 24px;
    flex-direction: column;

    @media screen and (min-width: 680px) {
        gap: 32px;
        flex-direction: row;
    }

    @media screen and (min-width: 1024px) {
        margin-bottom: 32px;
    }

    div {
        width: 100%;
    }
}

.input:not(:last-child) {
    margin-bottom: 24px;

    @media screen and (min-width: 1024px) {
        margin-bottom: 32px;
    }
}

.btnSave {
    display: flex;
    justify-content: center;
    padding-bottom: 120px;

    @media screen and (min-width: 680px) {
        padding-bottom: 0;
    }

    @media screen and (min-width: 1024px) {
        display: block;
        justify-content: inherit;
    }

    button {
        width: 100%;
        justify-content: center;

        @media screen and (min-width: 1024px) {
            width: inherit;
        }
    }
}