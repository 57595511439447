.missionItem {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: var(--grey100);
  border-radius: 100px;
}

.drag {
  padding: 8px;
  cursor: grab;
  display: flex;
  svg {
    font-size: 18px;
    width: 18px;
    height: 18px;
    color: #000;
  }
  &:active {
    cursor: grabbing;
  }
}

.missionName {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 12px 16px;
  border-radius: 100px;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit {
  padding: 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  svg {
    width: 18px;
    height: 18px;
    color: #000;
  }
  &:hover {
    background-color: var(--grey300);
  }
}

.delete {
  padding: 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 18px;
    height: 18px;
    color: #000;
  }
  &:hover {
    background-color: var(--grey300);
  }
}
