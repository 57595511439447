.m-points {
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    color: var(--grey600);
    padding: 4px 10px;
    border-radius: 17px;
    &--border {
        border: 1.4px solid var(--grey300);
        background-color: #fff;
    }
    &--fill {
        background: var(--grey300);
    }
}
