.header {
  display: flex;
  height: 64px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: #fff;

  @media screen and (min-width: 1024px) {
   background-color: transparent;
   height: auto;
  }
}

.box {
  padding: 24px;
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.close {
  margin-left: auto;
}

.customers {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 24px 0;

  li {
    max-width: 100%;

    button {
      padding: 12px 16px;
      border-radius: 100px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      position: relative;
      z-index: 1;
      max-width: 100%;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 100px;
        background-color: inherit;
        transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
        z-index: -1;
      }

      &:hover {
        &::after {
          transform: scale(1.1);
        }
      }
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 80px;
    height: 80px;
  }
}

.noCustomer {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--grey500);
  text-align: center;
}