.m-checkbox {
    position: relative;
    input {
        position: absolute;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        &:checked ~ .checkmark--black {
            background-color: #000;
            svg {
                display: block;
                color: #fff;
            }
        }
        &:checked ~ .checkmark--white {
            svg {
                display: block;
                color: #000;
            }
        }
    }
    .checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        svg {
            font-size: 18px;
            display: none;
            color: #000;
        }
        &--white {
            background-color: #fff;
        }
        &--black {
            background-color: var(--grey200);
        }
    }
}
