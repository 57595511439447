.finishSprintPopup>div:first-child>div:first-child {
    padding-bottom: 20px;
}

.btnFinish {
    width: 50%;

    @media screen and (min-width: 1024px) {
        width: inherit;
    }

    button {
        width: 100%;
        justify-content: center;
    }
}

.buttons {
    button {
        width: 100%;
        justify-content: center;

        @media screen and (min-width: 1024px) {
            width: inherit;
            justify-content: initial;
        }
    }
}