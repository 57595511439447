.wrapper {
  height: 70vh;
  width: 100vw;
  position: relative;
  bottom: -173px;
  padding: 20px;
  overflow-y: auto;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    height: 100vh;
    width: 528px;
    bottom: inherit;
    padding: 43px 40px;
  }
}

.header {
  margin-bottom: 32px;
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    button {
      height: 42px;
      width: 42px;
    }
    h3 {
      color: #000;
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
    }
    p {
      margin-top: 8px;
      color: #646464;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.report {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;
  border-bottom: 1px solid #d4d9e8;
  &:first-child {
    border-top: 1px solid #d4d9e8;
  }
  &__information {
    &__tags {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 8px;
      &__single {
        border-radius: 100px;
        padding: 4px 8px;
        color: #000;
        font-size: 12px;
        font-weight: 500;
      }
    }
    &__dates {
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: var(--grey600);
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &__links {
    position: relative;
    display: flex;
    justify-content: space-between;
    &__buttons {
      width: 40px;
      height: 40px;
      border-radius: 32px;
      padding: 10px;
      svg {
        color: #5b5b6d;
      }
      &:hover {
        background-color: #e6ecf4;
        svg {
          color: #000000;
        }
      }
    }
    &__copied {
      position: absolute;
      bottom: 120%;
      right: 35%;
      display: flex;
      padding: 6px 8px;
      align-items: center;
      border-radius: 8px;
      background-color: #000;
      width: 70px;
      &__text {
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 64px;
    height: 64px;
  }
}

.empty {
  margin-top: 8px;
  color: #646464;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
