.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  background-color: var(--grey100);
  padding: 8px;
}

.mission {
  padding: 12px 16px;
  border-radius: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
  @media screen and (min-width: 768px) {
    max-width: 80%;
    width: auto;
  }
}
