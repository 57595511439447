.wrapper {
  height: 100%;
  padding: 24px 0;

  @media screen and (min-width: 1024px) {
    height: 100vh;
    padding: 40px 0 24px;
  }
}

.wrapper>div:first-child {
  padding: 0 0 0 16px;
  height: 100%;

  @media screen and (min-width: 700px) {
    padding: 0 16px;
  }
}

.header {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;

  @media screen and (min-width: 700px) {
    padding-right: 0;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 40px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 42px;
  color: #000000;
  margin-right: 15px;

  @media screen and (min-width: 400px) {
    margin-right: 0;
  }

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
}

.flex_wrapper {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  height: calc(100vh - 190px);
  overflow-x: auto;
  margin-left: -16px;

  @media screen and (min-width: 1024px) {
    height: calc(100vh - 80px - 71px);
    margin-left: inherit;
  }
}

.left {
  position: relative;
  padding: 16px 12px;
  height: 100%;
  min-width: 322px;
  background-color: var(--grey200);
  transition: background-color 0.1s ease-in;
  width: 50%;
  overflow: hidden;
  margin-left: 16px;

  @media screen and (min-width: 1024px) {
    margin-left: inherit;
  }
}

.hovered {
  background-color: #aab1c733;
}

.right {
  width: 50%;
  position: relative;
  height: 100%;
}

.backlog {
  height: inherit;
}

.add {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 64px;
    height: 64px;
  }
}