.files {
    li {
        margin-bottom: 16px;
    }
}

.addFile {
    margin-bottom: 32px;

    @media screen and (min-width: 1024px) {
        margin-bottom: 40px;
    }
}

.addFile {
    display: flex;
    position: relative;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;

    svg {
        fill: #000;
        font-size: 18px;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        font-size: 0;
        z-index: 2;

        &::file-selector-button {
            font-size: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            margin: 0;
            padding: 0;
            font-size: 0;
            height: 100%;
            width: 100%;
            border: none;
            background-color: transparent;
        }
    }
}