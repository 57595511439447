.draggingDroppable {
  .task {
    background-color: transparent !important;

    .drag {
      opacity: 0 !important;
    }
  }
}

.task {
  padding: 8px 32px 8px 0;
  display: flex;
  align-items: center;
  gap: 16px;
  .drag {
    opacity: 1;
  }

  @media screen and (min-width: 1024px) {
    background-color: transparent;
    margin-left: -48px;
    width: calc(100% + 96px);
    .drag {
      opacity: 0;
    }
  }

  &:not(.dragging):hover {
    background-color: var(--grey100);

    .drag {
      opacity: 1;
    }
  }

  &.dragging {
    background-color: var(--grey100) !important;

    .drag {
      opacity: 1 !important;
    }
  }
}

.isNotDragging:hover {
  background-color: var(--grey100);

  .drag {
    opacity: 1;
  }
}

.drag {
  opacity: 0;
  display: flex;

  .dots {
    width: 20px;
    height: 20px;
    color: var(--grey500);

    &:hover {
      cursor: grab;
    }
  }

  &:focus {
    opacity: 1 !important;
  }
}

.idle {
  background-color: transparent !important;

  .drag {
    opacity: 0 !important;
  }
}

.delete {
  display: flex;
  width: 142px;
  height: 33px;
  position: relative;

  .deleteZone {
    position: absolute;
    top: 0;
    left: 0;

    svg {
      width: 142px;
      height: 33px;
    }
  }
}

.top {
  position: absolute;
  top: 0;
  right: 48px;
}

.subtitle {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.deleteTask {
  opacity: 0.6;
}

.shaking {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}