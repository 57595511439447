.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:hover {
        .play {
            opacity: 1;
        }
    }
}

.container {
    width: 100%;
}

.info {
    display: flex;
    align-items: center;
    gap: 12px;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.usersDropdownContainer {
    position: relative;
}

.image {
    width: 21px;
    min-width: 21px;
    height: 21px;
    border-radius: 100px;
    overflow: hidden;
    background-color: var(--grey300);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    svg {
        color: #000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        background-color: var(--grey400);
    }
}

.title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--grey800);
    margin-bottom: 6px;
    width: 100%;
    resize: none;

    &::placeholder {
        color: #000;
        opacity: 0.3;
        font-weight: 400;
    }
}

.titleLined {
    text-decoration: line-through;
    color: var(--grey500);
}

.statusContainer {
    display: flex;
    position: relative;

    .statuses {
        position: absolute;
        z-index: 10;
        top: calc(100% + 8px);
        left: 0;

    }

    .statuses> :first-child {
        position: initial;
        width: auto;
    }
}

.usersDropdownContainer {
    .statuses {
        position: fixed;
        width: 100%;
        height: 100vh;
        bottom: 72px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;

        @media screen and (min-width: 768px) {
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            width: 350px;
            height: inherit;
            bottom: calc(100% + 8px);
            left: 0;
            transform: inherit;
            background-color: inherit;
            z-index: 10;
        }
    }
}

.status {
    padding: 4px 8px;
    border-radius: 17px;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    display: flex;
    align-items: center;
    position: relative;

    svg {
        width: 13px;
        height: 13px;
        margin-left: 4px;
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 17px;
        z-index: 2;
        background-color: transparent;
    }

    &:hover {
        &:after {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.complexity {
    border-radius: 100px;
    border: 2px solid var(--grey300);
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    width: 26px;
    height: 21px;
    text-align: center;

    &::placeholder {
        color: var(--grey600);
    }

    &:hover {
        border-color: var(--grey400);
    }
}

.statusList {
    width: 94px;
    border-radius: 6px;
    padding: 8px;
    box-shadow: 0px 12px 36px 0px #00000029;
    background-color: #fff;
}

.actionItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 8px;
    border-radius: 6px;
    background-color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    width: 100%;
    color: var(--grey600);

    &:hover {
        background-color: var(--grey100);
    }
}

.play {
    opacity: 1;
    margin-left: auto;
    min-width: 25px;
    height: 25px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: #000;
    position: relative;

    @media screen and (min-width: 1024px) {
        opacity: 0;
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100px;
        transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
        z-index: -1;
        background-color: #000;
    }

    &:hover {
        &::after {
            @media screen and (min-width: 1024px) {
                transform: scale(1.3);
            }
        }
    }

    svg {
        font-size: 9px;
        color: #fff;
    }

    &:focus {
        opacity: 1;
    }
}

.pause {
    margin-left: auto;
    min-width: 25px;
    height: 25px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100px;
        transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
        z-index: -1;
        background-color: #000;
    }

    &:hover {
        &::after {
            transform: scale(1.3);
        }
    }

    svg {
        font-size: 9px;
        color: #fff;
    }
}