.m-top-points {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    &__text {
        font-size: 13px;
        font-weight: 700;
        line-height: 17px;
    }
}
