.m-label {
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    margin-bottom: 8px;
    display: block;
    span {
        color: var(--red);
    }
    &--dark {
        color: #000;
    }
    &--white {
        color: #fff;
    }
}
