.notes {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: var(--grey800);
  resize: vertical;
  padding: 2px;
  width: 100%;
  resize: none;
  text-align: left;
  margin-top: 8px;
  &::placeholder {
    color: #000;
    opacity: 0.5;
  }
  svg {
    margin-right: 8px;
    font-size: 18px;
  }
}

.editorStyle {
  color: var(--gris-800, #151419);
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  position: relative;
  z-index: 0;
  ul {
    padding-left: 20px;
    margin-bottom: 8px;
    li {
      list-style: disc;
    }
  }
  strong {
    color: var(--grey800);
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
  }
  a {
    color: #ac7c00;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration-line: underline;
  }
}
