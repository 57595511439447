.main {
    display: inline-flex;
    align-items: center;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
    padding: 12px 16px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #000;
    animation-name: toastAppear;
    animation-duration: 0.3s;
    svg {
        font-size: 18px;
        color: #000;
        margin-right: 8px;
    }
}

.error {
    background-color: var(--red);
}

.success {
    background-color: var(--light-green);
}

@keyframes toastAppear {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}
