.headerContainer {
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    gap: 10px;

    @media screen and (min-width: 1024px) {
        padding: 0 0 0 24px;
        gap: 0;
    }
}

.title {
    color: #fff;
    padding: 20px 0;
    font-size: 24px;

    @media screen and (min-width: 1024px) {
        padding: 40px 0 24px 16px;
        font-size: 32px;
    }
}

.subpages {
    display: flex;
    gap: 60px;

    @media screen and (min-width: 1024px) {
        gap: 24px;
    }
}

.activePage {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    position: relative;
    padding: 16px 0;

    @media screen and (min-width: 1024px) {
        padding: 12px 16px;
    }

    &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        width: calc(100% + 16px);
        height: 4px;
        background-color: #fff;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 30px 30px 0 0;
    }
}

.inactivePage {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    opacity: 0.5;
    padding: 16px 0;

    @media screen and (min-width: 1024px) {
        padding: 12px 16px;
    }
}

.container {
    padding: 24px 24px 0 24px;
    background-color: #fff;

    @media screen and (min-width: 680px) {
        padding: 24px 24px 46px 24px;
    }

    @media screen and (min-width: 1024px) {
        padding: 42px 0;
        max-width: 624px;
        margin: 0 auto;
        background-color: transparent;
        height: inherit;
    }
}