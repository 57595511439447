.m-button-small {
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  position: relative;
  z-index: 1;
  width: fit-content;
  svg {
    min-width: 16px;
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
  &--black {
    background-color: #000;
    color: #fff;
    svg {
      font-size: 18px;
      color: #fff;
      width: 18px;
      height: 18px;
      fill: #fff;
    }
    &::after {
      background-color: #000;
    }
  }
  &--grey {
    background-color: var(--grey200);
    color: #000;
    svg {
      fill: #000;
      width: 18px;
      height: 18px;
      font-size: 18px; 
      color: #000;
    }
    &::after {
      background-color: var(--grey200);
    }

    &:hover {
      background-color: var(--grey300);
      &::after {
        background-color: var(--grey300);
      }
    }
  }
  &--red {
    background-color: #f65555;
    color: #fff;
  }
  &--white {
    background-color: #fff;
    color: #000;
    svg {
      font-size: 18px;
      color: #000;
      fill: #000;
    }
    &::after {
      background-color: #fff;
    }
  }
  &--border {
    background-color: #fff;
    color: var(--grey600);
    &::after {
      left: -2px;
      top: -2px;
      height: calc(100% + 3px);
      width: calc(100% + 3px);
      border: 1px solid var(--grey300);
      background-color: #fff;
    }
    &:hover {
      &::after {
        border-color: var(--grey400);
      }
    }
  }
  &--black-opacity {
    background: #292929;
    color: #fff;
    svg {
      font-size: 18px;
      color: #fff;
      fill: #fff;
    }
    &::after {
      background-color: #292929;
    }
  }
  &--opacity {
    background-color: #122f8c17;
    svg {
      fill: #000;
      width: 18px;
      height: 18px;
      font-size: 18px;
      color: #000;
    }
    &::after {
      background-color: transparent;
    }
    &:hover {
      background-color: transparent;
      &::after {
        background-color: #2d42872e;
      }
    }
  }
  &--rounded {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--ratio {
    padding: 12px;
  }
  &--full {
    width: 100%;
    justify-content: center;
  }
  &--tronc {
    max-width: 100%;
    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
