.subtitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  color: #000;
  margin-bottom: 24px;
}

.wrapper {
  background-color: #fff;
  max-width: 624px;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 40px 64px;
  }
}

.input:not(:last-child) {
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 32px;
  }
}

.passwordInput {
  display: flex;
  position: relative;
  align-items: center;

  button {
    position: absolute;
    right: 16px;

    svg {
      color: var(--grey500);

      &:hover {
        color: var(--grey700);
      }
    }
  }
}

.btnSave {
  display: flex;
  justify-content: center;
  padding-bottom: 265px;

  @media screen and (min-width: 1024px) {
    display: block;
    justify-content: inherit;
    padding-bottom: 0;
  }

  button {
    width: 100%;
    justify-content: center;

    @media screen and (min-width: 1024px) {
      width: inherit;
    }
  }
}