.wrapper {
  background-color: #fff;
  height: 100%;
  @media screen and (min-width: 768px) {
    min-height: 195px;
  }
}

.header {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 768px) {
      font-size: 22px;
    }
  }
  .modifButton {
    display: flex;
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
    svg {
      font-size: 18px;
      color: #000000;
    }
  }
}

.content {
  padding: 40px 24px 24px 24px;
}

.progress {
  margin-bottom: 10px;
}

.hours {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  p {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    color: #000000;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
}

.date {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #646464;
  }
  svg {
    color: #646464;
    font-size: 18px;
    width: 18px;
    height: 18px;
    min-width: 18px;
  }
}

.red {
  background-color: var(--red);
  border-radius: 100px;
  padding: 4px 8px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
}
