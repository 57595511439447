.options {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 1024px) {
    align-items: flex-start;
  }
}

.optionsRight {
  @extend .options;
  position: inherit;

  @media screen and (min-width: 1024px) {
    margin-bottom: 24px;
  }

  .optionsActions {
    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 24px;
      right: 24px;
      left: inherit;
      bottom: inherit;
      transform: none;
    }
  }
}

.id {
  color: var(--grey400);
  font-size: 13px;
  font-weight: 700;
}

.optionsActions {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  height: 72px;
  background-color: #fff;
  box-shadow: 0px -12px 52px 0px rgba(0, 0, 0, 0.11),
    0px -1px 7px 0px rgba(0, 0, 0, 0.08);
  gap: 8px;
  padding: 0 24px;

  @media screen and (min-width: 768px) {
    width: auto;
    position: relative;
    box-shadow: none;
    height: auto;
    background-color: transparent;
    padding: 0;
  }

  @media screen and (min-width: 1024px) {
    transform: translate(24px, -16px);
  }

  > :first-child {
    @media screen and (min-width: 768px) {
      order: 3;
    }
  }

  > :nth-child(2) {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }

  > :nth-child(3) {
    @media screen and (min-width: 768px) {
      order: 1;
    }
  }
}

.optionsWrapper {
  position: relative;
}

.editOptionsWrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;

  @media screen and (min-width: 1024px) {
    position: relative;
    background-color: transparent;
    height: inherit;
    width: inherit;
    right: 0;
    left: inherit;
    transform: none;
  }
}

.editOptions {
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  min-width: 280px;
  background-color: #fff;
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  animation: slideInFromBottom 0.3s ease forwards;
  padding: 16px;
  @media screen and (min-width: 1024px) {
    top: calc(100% + 8px);
    position: absolute;
    bottom: initial;
    right: 0;
    left: inherit;
    width: max-content;
    border-radius: 16px;
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  border-radius: 6px;

  svg {
    font-size: 17px;
  }

  &:hover {
    background-color: var(--grey100);
  }
}

.submit {
  margin-left: auto;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.confirmation {
  z-index: 4;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 8px;

  @media screen and (min-width: 768px) {
    width: auto;
    padding: 0;
    border-radius: 16px;
  }

  > :first-child {
    order: 1;
  }

  > :nth-child(2) {
    order: 2;

    &:hover {
      background-color: #c44545;
    }
  }

  button {
    display: flex;
    justify-content: center;
    width: 20%;
    height: 40px;
    padding: 0 16px;

    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }
}

.loadingIcon {
  svg {
    color: white;
  }
}
