.m-button {
  font-size: 14px;
  font-weight: 700;
  height: 48px;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  position: relative;
  z-index: 1;
  width: max-content;
  white-space: nowrap;

  svg {
    min-width: 18px;
    font-size: 18px;
    width: 18px;
    height: 18px;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: inherit;
  }

  &:hover {
    &::after {
      @media screen and (min-width: 1024px) {
        transform: scale(1.1);
      }
    }
  }

  &--black {
    background-color: #000;
    color: #fff;

    svg {
      font-size: 18px;
      color: #fff;
      width: 18px;
      height: 18px;
      fill: #fff;
    }

    &::after {
      background-color: #000;
    }
  }

  &--grey {
    background-color: var(--grey200);
    color: #000;

    svg {
      fill: #000;
      width: 18px;
      height: 18px;
      font-size: 18px;
      color: #000;
    }

    &::after {
      background-color: var(--grey200);
    }

    &:hover {
      @media screen and (min-width: 1024px) {
        background-color: var(--grey300);

        &::after {
          background-color: var(--grey300);
        }
      }
    }
  }

  &--white {
    background-color: #fff;
    color: #000;

    svg {
      font-size: 18px;
      color: #000;
      fill: #000;
    }

    &::after {
      background-color: #fff;
    }
  }

  &--border {
    background-color: #fff;
    color: var(--grey600);

    &::after {
      left: -2px;
      top: -2px;
      height: calc(100% + 3px);
      width: calc(100% + 3px);
      border: 1px solid var(--grey300);
      background-color: #fff;
    }

    &:hover {
      &::after {
        border-color: var(--grey400);
      }
    }
  }

  &--black-opacity {
    background: #292929;
    color: #fff;

    svg {
      font-size: 18px;
      color: #fff;
      fill: #fff;
    }

    &::after {
      background-color: #292929;
    }
  }

  &--opacity {
    background-color: #122f8c17;

    svg {
      fill: #000;
      width: 18px;
      height: 18px;
      font-size: 18px;
      color: #000;
    }

    &::after {
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;

      &::after {
        background-color: #2d42872e;
      }
    }
  }

  &--rounded {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  &--full {
    width: 100%;
    justify-content: center;
  }

  &--tronc {
    max-width: 100%;

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}