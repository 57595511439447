.container {
    background-color: var(--grey200);
    padding: 16px 12px;
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 300px;
    transition: background-color 0.1s ease-in;
}

.addNew {
    // position: absolute;
    // left: 50%;
    // bottom: 20px;
    // transform: translateX(-50%);
    margin: 25px auto 0 auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: var(--grey600);

    svg {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        color: var(--grey600);
    }

    &:hover {
        color: var(--grey900);

        svg {
            color: var(--grey900);
        }
    }
}

.hovered {
    background-color: #aab1c733;
}

.stories {
    height: calc(100% - 80px);
    overflow-y: scroll;
    margin-top: 16px;
    overflow-x: hidden;

    .story:not(:last-child) {
        margin-bottom: 8px;
    }
}