.m-textarea {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  height: 128px;
  padding: 12px 16px;
  width: 100%;
  border: 1px solid var(--grey300);
  resize: none;
  border-radius: 12px;
  &--dark {
    background-color: var(--grey700);
    color: #fff;
    &::placeholder {
      color: var(--grey400);
    }
    &:focus {
      border-color: #fff;
    }
    &:disabled {
      background-color: var(--grey800);
      cursor: not-allowed;
      &::placeholder {
        color: var(--grey600);
      }
      &:hover {
        background-color: var(--grey800);
      }
    }
    &:hover {
      background-color: var(--grey800);
    }
  }
  &--grey {
    border: 1px solid var(--grey300);
    background-color: var(--grey100);
    color: var(--grey600);
    &::placeholder {
      color: var(--grey400);
    }
    &:focus {
      border-color: #000;
    }
    &:disabled {
      background-color: var(--grey100);
      cursor: not-allowed;
      &::placeholder {
        color: var(--grey400);
      }
      &:hover {
        background-color: var(--grey100);
      }
    }
    &:hover {
      background-color: var(--grey300);
    }
  }
}
