.main {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 30px 0 0;
  overflow-y: auto;

  @media (min-width: 768px) {
    align-items: center;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 1024px) {
    align-items: center;
    padding: 40px;
  }
}

.box {
  background-color: #fff;
  border-radius: 32px 32px 0px 0px;
  width: 100%;
  position: relative;
  overflow: auto;
  max-height: 80vh;
  overflow: auto;
  @media (min-width: 768px) {
    border-radius: 0px;
    max-width: 515px;
    max-height: 90vh;
  }
  &.classcolorPanelName {
    max-width: 534px;
  }
  &.classImageUpload {
    max-width: 1200px;
    width: auto;
  }
}

.boxHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  height: 64px;
  border-bottom: 1px solid var(--Neutral-Gray-200);
  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }

  .title {
    color: var(--Neutral-Gray-900);
    font-size: 16px;
    font-weight: 700;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 24px;
    display: flex;
    height: 40px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-Gray-200);
    background: var(--Neutral-White-White);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.02),
      0px -4px 8px 0px rgba(0, 0, 0, 0.02) inset,
      0px 2px 0px 0px rgba(0, 0, 0, 0.02), 0px 1px 0.5px 0px rgba(0, 0, 0, 0.05);
    @media screen and (min-width: 768px) {
      top: initial;
      right: initial;
      position: relative;
    }
    svg {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      color: var(--Neutral-Gray-500);
    }
  }
}
