.wrapper {
    background-color: #fff;
    height: 100vh;
    max-width: 528px;
    position: relative;
    padding: 48px;
    overflow-y: auto;
}

.params {
    top: 24px;
    right: 24px;
    position: absolute;
    padding: 12px 16px;
    background-color: var(--grey100);
    border-radius: 100px;
    display: flex;
    svg {
        fill: #000;
        font-size: 18px;
        width: 18px;
        height: 18px;
    }
}

.number {
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    color: var(--grey400);
    margin-bottom: 8px;
}

.title {
    font-size: 26px;
    font-weight: 700;
    line-height: 34px;
    color: #000;
    margin-bottom: 16px;
}

.tags {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 16px;
    ul {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
    }
}

.plusButton {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--grey300);
    border-radius: 100px;
    display: flex;
    svg {
        fill: #000;
        font-size: 18px;
    }
}

.persons {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
}

.points {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--grey300);
    border-radius: 100px;
    display: flex;
    width: fit-content;
    padding: 0 18px;
}

.subtitle {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: #000;
    margin-bottom: 16px;
}

.tasks {
    margin-bottom: 16px;
}

.addTask {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    svg {
        fill: #000;
        font-size: 18px;
    }
}

.notes {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: var(--grey800);
    margin-bottom: 40px;
}

.files {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
