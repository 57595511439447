.wrapper {
  width: 100%;
  background-color: var(--grey200);
}

.hover {
  &:hover {
    .progress {
      height: 20px;
    }
    .hoverText {
      opacity: 1;
    }
  }
}

.progress {
  height: 8px;
  max-width: 100%;
  position: relative;
  z-index: 1;
  transition: height 0.1s ease, width 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.progressMain {
  height: 24px;
  max-width: 100%;
  position: relative;
  z-index: 10;
  transition: height 0.1s ease, width 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.hoverText {
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  opacity: 0;
  transition: opacity 0.1s ease;
}
