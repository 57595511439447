.container {
    .day:first-child {
        .dayInfo {
            margin-top: 40px;
        }
    }
}

.dayInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 64px;
}

.date {
    color: #000;
    font-size: 18px;
    font-weight: 700;
}

.dayTotalTime {
    padding: 12px 16px;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    border-radius: 100px;
    background: var(--grey300);
}

.timerContainer {
    margin-bottom: 8px;
}
