.usersList {
    box-shadow: 0px 12px 36px 0px #00000029;
    background-color: #fff;
    padding: 16px;
    border-radius: 16px 16px 0px 0px;
    width: 100vw;
    position: fixed;
    bottom: 0px;
    animation: slideInFromBottom 0.3s ease forwards;
    z-index: 11;

    @media screen and (min-width: 768px) {
        animation: none;
        border-radius: 6px;
        padding: 8px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        position: inherit;
        bottom: inherit;
    }

    li {
        @media screen and (min-width: 768px) {
            width: calc(33% - 5px);
        }
    }
}

.picture {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: var(--grey300);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    color: var(--grey600);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    svg {
        width: 16px;
        height: 16px;
    }
}

.actionItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 8px;
    border-radius: 6px;
    background-color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    width: 100%;
    color: var(--grey600);

    &:hover {
        background-color: var(--grey100);
    }
}

@keyframes slideInFromBottom {
    0% {
        bottom: -50px;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}