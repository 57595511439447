.headerContainer {
    padding: 0 48px 0 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.title {
    color: #fff;
    padding: 40px 0 24px 16px;
}

.subpages {
    display: flex;
    gap: 24px;
}

.activePage {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    position: relative;
    padding: 12px 16px;
    &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        width: calc(100% + 16px);
        height: 4px;
        background-color: #fff;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 30px 30px 0 0;
    }
}

.inactivePage {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    opacity: 0.5;
    padding: 12px 16px;
}

.container {
    padding: 42px 0;
    max-width: 624px;
    margin: 0 auto;
}

.headWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
}

.wrapper {
    padding: 40px 0 120px 0;
}

.subtitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
}

.inputContainer {
    position: relative;
    margin-bottom: 24px;
    :global(.m-button) {
        position: absolute;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
    }
}

.mailInput {
    width: 100%;
    padding: 23px 130px 23px 32px;
    border-radius: 56px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    &::placeholder {
        color: var(--grey400);
    }
}

.memberHead {
    display: none;

    @media screen and (min-width: 1024px) {
        padding: 16px 24px 16px 72px;
        display: flex;
        align-items: center;
        background-color: #000;
        margin-bottom: 8px;
        p {
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            color: #fff;
            width: calc(100% / 5);
        }
      }
}
