.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  @media screen and (min-width: 1024px) {
    top: 0;
    height: 100vh;
  }
}

.popup {
  position: relative;
  // background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 768px) {
    width: auto;
  }
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (min-width: 1024px) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    padding: 48px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
  }

  .popup {
    width: 100%;

    @media screen and (min-width: 1024px) {
      width: 100%;
      max-width: calc(100% - 80px);
      height: 100%;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slideout {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}