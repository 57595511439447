.stack {
  padding: 16px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: var(--grey200);
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;

  @media screen and (min-width: 1150px) {
    row-gap: 0;
    flex-wrap: nowrap;
  }
}

.wrapperTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 450px;
  flex-shrink: 0;
  @media screen and (min-width: 768px) {
    margin-right: 8px;
  }
}

.stackCount {
  border-radius: 8px;
  background-color: var(--grey200);
  padding: 8px 12px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0px;
  flex-shrink: 0;

  &:hover {
    background-color: var(--grey300);
  }

  
}

.name {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  margin-right: 16px;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
 
}

.subTask {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: var(--grey500);
  margin-right: 16px;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.tagMission {
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  height: 21px;
  min-width: 21px;
  padding: 4px 8px;
  p {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #000;
    display: -webkit-box;
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.duration {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;

  @media screen and (min-width: 768px) {
    margin: 0 2px 0 auto;
  }
}

.options {
  width: 10%;
}

.singleTimer {
  &:nth-child(2) {
    margin-top: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.rightContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.play {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #000;
  position: relative;
  z-index: 1;
  margin-right: 0px;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: transparent;
    background-color: #000;
  }

  &:hover {
    &::after {
      transform: scale(1.1);
    }
  }

  svg {
    width: 12px;
    height: 12px;
    color: #fff;
  }
}