.headerContainer {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    padding: 0 48px 0 24px;
  }
}

.headerButtons {
  display: flex;
  column-gap: 16px;
  white-space: nowrap;

  :global(.m-button) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 163px;
  }

  :global(.m-button--white) {
    justify-content: space-between;
  }
}

.title {
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
  font-size: 24px;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 64px;
    height: 64px;
  }
}

.subpages {
  display: flex;
  gap: 24px;
  justify-content: center;

  @media screen and (min-width: 768px) {
    padding-left: 66px;
    justify-content: flex-start;
  }
}

.activePage {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  position: relative;
  padding: 12px 16px;

  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: #000;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 30px 30px 0 0;
  }
}

.inactivePage {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  opacity: 0.5;
  padding: 12px 16px;

  &:hover {
    opacity: 1;
  }
}

.container {
  padding: 42px 0;
  max-width: 624px;
  margin: 0 auto;
}

.headWrapper {
  display: flex;
  gap: 24px;

  align-items: center;
  padding: 24px 0;
  @media screen and (min-width: 1024px) {
    padding: 40px 0 24px 16px;
  }
}

.back {
  padding: 12px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.16);
  display: flex;
  position: relative;
  z-index: 1;
  transition: background-color 0.2s ease;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1),
      background-color 0.25s ease;
    z-index: -1;
    background-color: transparent;
  }

  &:hover {
    @media screen and (min-width: 1024px) {
      background-color: transparent;

      &::after {
        transform: scale(1.1);
        background: rgba(0, 0, 0, 0.24);
      }
    }
  }

  svg {
    font-size: 18px;
    color: #000;
  }
}

.edit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: auto;
  width: 42px;
  height: 42px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 1;
  transition: background-color 0.2s ease;

  @media screen and (min-width: 768px) {
    height: auto;
    padding: 12px 16px;
    width: auto;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1),
      background-color 0.25s ease;
    z-index: -1;
    background-color: transparent;
  }

  &:hover {
    @media screen and (min-width: 1024px) {
      background-color: transparent;

      &::after {
        transform: scale(1.1);
        background: rgba(0, 0, 0, 0.24);
      }
    }
  }

  svg {
    font-size: 18px;
  }

  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
}

.content {
  padding: 40px 0 90px;
  @media screen and (min-width: 1024px) {
    padding: 40px 0;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    margin-right: auto;
  }

  :global(.m-search) {
    max-width: 289px;
  }
}

.dropdownContainer {
  position: relative;
}

.dropdown {
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  @media screen and (min-width: 1024px) {
    position: absolute;
    min-width: 203px;
    width: auto;
    height: inherit;
    bottom: inherit;
    left: inherit;
    right: 0;
    top: calc(100% + 8px);
    background-color: inherit;
    z-index: inherit;
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  border-radius: 6px;

  svg {
    font-size: 17px;
  }

  &:hover {
    background-color: var(--grey100);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  row-gap: 24px;

  li {
    padding: 0 12px;
    width: calc(100% / 3);
  }
}