.container {
  background-color: #fff;
  padding: 24px;
  border-left: 6px solid;
  width: 100%;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09),
      0px 12px 24px rgba(0, 0, 0, 0.08);
  }
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #606060;
}
