.picture {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 24px;
    position: relative;

    @media screen and (min-width: 1024px) {
        margin-bottom: 40px;
    }
}

.blackPen {
    background-color: black;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 65px;
    bottom: 0;

    svg {
        font-size: 20px;
        color: white;
    }

    @media screen and (min-width: 768px) {
        display: none;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        font-size: 0;
        z-index: 2;

        &::file-selector-button {
            font-size: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            margin: 0;
            padding: 0;
            font-size: 0;
            height: 100%;
            width: 100%;
            border: none;
            background-color: transparent;
        }
    }
}

.pictureImg {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.pictureSvg {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: var(--grey300);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 48px;
    }
}

.file {
    display: none;

        @media screen and (min-width: 768px) {
            position: relative;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 16px;
        
            svg {
                font-size: 18px;
                color: #000;
            }
        
            p {
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
            }
        
            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
                background-color: transparent;
                font-size: 0;
                z-index: 2;
        
                &::file-selector-button {
                    font-size: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    margin: 0;
                    padding: 0;
                    font-size: 0;
                    height: 100%;
                    width: 100%;
                    border: none;
                    background-color: transparent;
                }
            }
    }
}

.infoFormat {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    color: var(--grey600);
    margin-bottom: 8px;
}

.infoWeight {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    color: var(--grey600);
}