.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: #fff;
  text-align: left;
}

.cardRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rowName {
    font-size: 12px;
    font-weight: 500;
  }
}

.endButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.name {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  flex-shrink: 0;
}

.capacity {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #646464;
  flex-shrink: 0;
  width: 50%;
}

.mail {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #646464;
  flex-shrink: 0;
  &__content {
    padding-top: 5px;
    position: relative;
    display: flex;
    align-items: center;
  }
  svg {
    margin-left: 8px;
    font-size: 18px;
    fill: var(--grey500);
    &:hover {
      fill: var(--grey900);
    }
  }
}

.toolTip {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  width: fit-content;
  top: -150%;
  left: -25%;
}

.phone {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #646464;
}

.editButton {
  padding: 0 4px;
  display: flex;
  margin-left: 32px;
  svg {
    font-size: 18px;
    color: #000;
  }
  &:hover {
    svg {
      color: var(--grey500);
    }
  }
}
