.wrapper {
    width: 300px;
    padding: 16px;
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    background-color: #fff;
}

.item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 8px;
    border-radius: 6px;
    width: 100%;
    &:hover {
        background-color: var(--grey100);
    }
    svg {
        font-size: 17px;
        color: #000;
    }
    p {
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        color: #000;
    }
}
