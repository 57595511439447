.otherActions {
  // top: calc(100% + 8px);
  // position: absolute;
  // min-width: 284px;
  // z-index: 11;

  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: calc(100% + 8px);
    left: -10%;
    min-width: 284px;
    z-index: 11;
    width: auto;
    height: inherit;
    bottom: inherit;
    transform: inherit;
    background-color: inherit;
  }
}

.actionItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100% !important;
  justify-content: flex-start !important;

  &:hover {
    background-color: var(--grey100);
  }
}

.actionIcon {
  font-size: 17px;
  color: #000;
}

.actionArrow {
  font-size: 17px;
  color: var(--grey400);
  margin-left: auto;
}