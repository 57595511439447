.container {
  display: flex;
  position: relative;
}

.containerData {
  position: relative;
  &::after {
    content: attr(data-text);
    position: absolute;
    z-index: 2;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translate(-50%, 10px);
    padding: 8px;
    background-color: #000;
    color: #fff;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease, transform 0.2s ease;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    border-radius: 8px;
  }
  &:hover::after {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.tag {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: #000;
}

.tooltip {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #000;

  &:hover {
    .container::after {
      display: block;
    }
  }
}
