.wrapper {
  height: 100vh;
  width: 100vw;
  padding: 24px;
  position: relative;
  overflow-y: auto;
  background-color: #fff;

  @media screen and (min-width: 1024px) {
    padding: 48px;

    width: 528px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 24px;

  @media screen and (min-width: 500px) {
    flex-direction: row;
    align-items: center;
    row-gap: 0;
  }

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 8px;

    :first-child {
      width: auto;
    }
  }

  p {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }
}

.input:not(:last-child) {
  margin-bottom: 32px;
}