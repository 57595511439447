.container {
  padding: 32px 24px;

  @media screen and (min-width: 1024px) {
    width: 100vw;
    height: 100vh;
    padding: 0;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 32px;

  @media screen and (min-width: 768px) {
    padding-top: 93px;
    margin: 0 auto;
  }
}

.wrapperForm {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    max-width: 1056px;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: #fff;
  margin-bottom: 40px;
}

.input {
  margin-bottom: 32px;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 32px;
}

.left {
  background-color: #000;

  @media screen and (min-width: 768px) {
    padding: 80px 72px;
  }
}

.remember {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
  }
}

.right {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  @media screen and (min-width: 768px) {
    padding: 55px 56px;
  }
}

.card {
  display: flex;
  gap: 24px;
  align-items: flex-start;

  .icon {
    border-radius: 100px;
    min-width: 56px;
    height: 56px;
    background-color: var(--grey300);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: flex;
      font-size: 28px;
      width: 28px;
      height: 28px;
      color: #000;
      fill: #000;
    }
  }

  .subtitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    color: #000;
    margin-bottom: 8px;
  }

  .cardText {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #646464;
  }
}

.passwordInput {
  display: flex;
  align-items: center;
  background-color: var(--grey700);
  position: relative;
  border-radius: 100px;

  button {
    position: absolute;
    right: 5px;

    svg {
      margin-top: 4px;
      margin-right: 8px;
      color: var(--grey500);

      &:hover {
        color: var(--grey200);
      }
    }
  }
}

.btnLogin {
  @media screen and (min-width: 768px) {
    width: 105px;
    height: 42px;
  }

  button {
    width: 100%;
    justify-content: center;
  }
}