.container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
}

.buttonContainer {
  gap: 0;
}

.button {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  gap: 8px;

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }

  p {
    transition: background-color 0.1s ease-in;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  svg {
    flex-shrink: 0;
  }

  .planningIcon {
    fill: #000;
    width: 18px;
    height: 18px;
  }
}

.noDate {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px 0 16px;
  transition: background-color 0.1s ease-in;
  border-radius: 100px;

  p {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .planningIcon {
    fill: #000;
    width: 18px;
    height: 18px;
    margin-right: 0px;

    @media screen and (min-width: 500px) {
      margin-right: 20px;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: var(--grey300);
  }

  &:hover {
    background-color: var(--grey300);

    &::after {
      transform: scale(1.1);
    }
  }
}

.arrow {
  width: 18px;
  height: 18px;
}

.calendar {
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 12;
    width: auto;
    height: inherit;
    bottom: inherit;
    left: inherit;
    transform: inherit;
    background-color: inherit;
  }
}

.resetFilters {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 100px;
  transform: translateX(12px);
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: var(--grey300);
  }

  svg {
    color: var(--grey600);
    width: 18px;
    height: 18px;
  }
}

.noDates {
  padding: 12px 16px 12px 0;
}

.startDate,
.endDate {
  padding: 12px 0px;
  border-radius: 100px;
  white-space: nowrap;
  font-size: 12px;

  @media screen and (min-width: 768px) {
    padding: 12px 16px;

    font-size: 14px;
  }
}

.endDate:hover,
.startDate:hover {
  @media screen and (min-width: 768px) {
    background-color: var(--grey300);
  }
}

.selectPerson {
  border-radius: 100px;
  background-color: var(--grey200);
  padding: 0 16px;
  display: flex;
  height: 48px;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  position: relative;
  z-index: 2;

  svg {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    color: #000;
    fill: #000;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 100px;
    color: #000;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    z-index: -1;
    transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    background-color: var(--grey200);
  }

  &:hover {
    &::after {
      transform: scale(1.1);
    }
  }
}

.resetMissionFilter {
  display: flex;
  position: absolute;
  font-size: 18px;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: var(--grey500);
  z-index: 2;
}

.personsDropdown {
  position: relative;
  width: 200px;

  @media screen and (min-width: 590px) {
    width: auto;
  }
}

.persons {
  width: 100%;

  @media screen and (min-width: 590px) {
    width: auto;
  }

  .selectPerson {
    width: 100%;
    justify-content: center;

    @media screen and (min-width: 590px) {
      width: auto;
    }
  }
}

.personsDropdownContent {
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: calc(100% + 8px);
    z-index: 12;
    right: 0;
    width: auto;
    height: inherit;
    bottom: inherit;
    left: inherit;
    transform: inherit;
    background-color: inherit;
  }
}
