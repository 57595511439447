.selectedCustomerButton {
    border: 2px solid #000;
    border-radius: 100px;
}

.suggestions {
    margin: 8px 0 8px 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    color: #000;
}

.suggestionsList {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.selectedMission {
    border: 2px solid #000;
}
