.report {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;
  border-bottom: 1px solid #d4d9e8;

  &:first-child {
    border-top: 1px solid #d4d9e8;
  }

  &__information {
    &__tags {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 8px;
      row-gap: 8px;
      flex-wrap: wrap;

      &__single {
        border-radius: 100px;
        padding: 4px 8px;
        color: #000;
        font-size: 12px;
        font-weight: 600;
      }
    }

    &__dates {
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: var(--grey600);
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__links {
    position: relative;
    display: flex;
    gap: 12px;

    &__buttons {
      border-radius: 32px;
      padding: 10px;
      display: flex;
      align-items: center;

      svg {
        color: #5b5b6d;
        font-size: 17px;
      }

      &:hover {
        background-color: #e6ecf4;

        svg {
          color: #000000;
        }
      }
    }

    &__copied {
      position: absolute;
      bottom: 120%;
      right: 35%;
      display: flex;
      padding: 6px 8px;
      align-items: center;
      border-radius: 8px;
      background-color: #000;
      width: 70px;

      &__text {
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.confirmation {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  width: fit-content;
  background-color: #fff;
  color: #000;
  border-radius: 16px;
  padding: 8px;
  box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.16);

  &__button {
    display: flex;
    white-space: nowrap;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
    align-items: center;
    padding: 16px;

    &:hover {
      background-color: var(--grey100);
    }

    svg {
      color: #000000;
      margin-right: 8px;
    }
  }
}