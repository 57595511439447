.container {
    display: flex;
    align-items: center;
    padding: 5px 20px 5px 5px;
    background-color: #fff;
    border-radius: 100px;
    &:hover {
        background-color: var(--grey300);
    }
}

.grey {
    background-color: var(--grey100);
    &:hover {
        background-color: var(--grey300);
    }
}

.picture {
    background-color: var(--grey300);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: var(--grey400);
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
    svg {
        width: 18px;
        height: 18px;
        color: #000;
    }
}

.name {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}
