.wrapper {
    padding: 16px 40px 72px 40px;
    background-color: #fff;
    position: relative;
}

.close {
    position: absolute;
    top: 32px;
    right: 40px;
    padding: 12px;
    background-color: #e3e6ea;
    border-radius: 100px;
    display: flex;
    svg {
        font-size: 18px;
        color: #000;
    }
}

.icon {
    display: flex;
    justify-content: center;
    svg {
        width: 120px;
        height: 120px;
    }
}

.title {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    text-align: center;
    color: #000;
    margin-bottom: 48px;
}

.stats {
    display: flex;
    padding: 0 42px;
    justify-content: space-between;
    margin-bottom: 29px;
}

.points {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconWrapper {
    padding: 14px;
    background-color: #e3e6ea;
    border-radius: 100px;
    margin-bottom: 16px;
    display: flex;
    svg {
        width: 28px;
        height: 28px;
        fill: #000;
    }
}

.number {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #000;
}

.done {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #646464;
}

.total {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #9d9d9d;
}

.button {
    width: fit-content;
    margin: 0 auto;
}
