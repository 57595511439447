:root {
    --grey900: #0f0e13;
    --grey800: #151419;
    --grey700: #202127;
    --grey600: #5b5b6d;
    --grey500: #8c8fa6;
    --grey400: #aab1c7;
    --grey300: #d4d9e8;
    --grey200: #e6ecf4;
    --grey100: #f1f4f9;
    --white: #ffffff;
    --dark-blue: #5568f6;
    --light-blue: #55e3f6;
    --cyan: #25e197;
    --light-green: #a5e125;
    --yellow: #f7e543;
    --orange: #f6a255;
    --red: #f65555;
    --pink: #f669ad;
    --purple: #a769f6;
}
