.endingPopup {
  position: relative;
  max-height: 90vh;
  padding: 22px;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 32px;
  right: 40px;
}

.fenek {
  display: flex;
  justify-content: center;

  svg {
    width: 88px;
    height: 107px;
  }
}

.title {
  margin: 24px 0 24px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;

  @media screen and (min-width: 1024px) {
    margin: 24px 0 48px 0;
  }
}

.stats {
  padding: 0 42px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 32px;
  }
}

.statIcon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--grey200);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px auto;

  svg {
    width: 28px;
    height: 28px;
    fill: #000;
  }
}

.statNumber {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.statTitle {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  color: #646464;
}

.statSubtitle {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #9d9d9d;
}

.label {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  margin-bottom: 8px;
}

.choice {
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey200);
  border-radius: 100px;
  width: 100%;
  max-width: calc(100% + 6px);
  gap: 0;
  margin-bottom: 8px;

  @media screen and (min-width: 460px) {
    flex-direction: row;
    gap: 8px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 32px;
  }

  button {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 16px;
    border-radius: 100px;
    white-space: nowrap;
    transition: background-color 0.1s ease-in;
    width: 90%;

    @media screen and (min-width: 460px) {
      width: 50%;
    }

    &:hover {
      &:not(.choiceActive) {
        background-color: var(--grey300);
      }
    }
  }
}

.choiceActive {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.validate {
  margin: 0 auto;
}

.selectSprintContainer {
  position: relative;
}

.selectSprint {
  padding: 12px 16px;
  background-color: var(--grey200);
  border-radius: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 32px;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: var(--grey300);
  }

  svg:first-child {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    flex-shrink: 1;
    fill: #000;
  }

  svg:last-child {
    margin-left: auto;
    width: 18px;
    height: 18px;
    flex-shrink: 1;
  }
}

.selectSprintDropdown {
  // position: absolute;
  // top: calc(100% + 8px);
  // left: 0;
  // width: 100%;
  // z-index: 2;

  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    height: inherit;
    bottom: inherit;
    transform: inherit;
    background-color: inherit;
    z-index: 2;
  }
}

.actionItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  color: var(--grey600);

  &:hover {
    background-color: var(--grey100);
  }
}
