.optionsContainer {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.options {
  padding: 5px;
  display: flex;

  svg {
    width: 17px;
    height: 17px;
    fill: var(--grey600);
  }

  &:hover {
    svg {
      fill: var(--grey700);
    }
  }
}

.dropdown {
  right: 20px;
  position: fixed;
  min-width: 220px;
  z-index: 12;

  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);

  @media screen and (min-width: 1024px) {
    width: auto;
    height: inherit;
    bottom: inherit;
    left: inherit;
    transform: inherit;
    background-color: inherit;
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  border-radius: 6px;

  svg {
    font-size: 17px;
  }

  &:hover {
    background-color: var(--grey100);
  }
}