.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon {
    padding: 12px;
    border-radius: 50%;
    background-color: var(--grey300);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    svg {
        width: 32px;
        height: 32px;
        font-size: 32px;
        color: #000;
        fill: #000;
    }
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;
    max-width: 315px;
}

.subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    max-width: 350px;
    color: #646464;
    margin-bottom: 24px;
}
