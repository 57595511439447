.container {
  display: flex;
  align-items: center;
  grid-template-columns: 2.5fr 2fr 1fr;
  background-color: #000;
  padding: 14px 16px 14px 24px;
  width: 100%;
  border-radius: 14px;
  transition: width 0.3s cubic-bezier(0.87, 0, 0.13, 1);
  margin-left: auto;

}

.timerHalfOpen {
  width: 230px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 0 0;

  .item:not(:last-child) {
    overflow: hidden;
    width: 0;
  }


}



.timerOpen {
  max-width: calc(100% - 32px);
  width: calc(100vw - 112px);

  .item {
    transition: width 0.1s linear 0.2s;
  }
}

.menuOpen {
  border-radius: 0 0 14px 14px;
}

.expendTimer {
  display: flex;
  padding: 23px 16px 23px 16px;
  background-color: #000;
  border-radius: 14px;
  transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1);

  &:hover {
    transform: translateX(-8px);
  }

  svg {
    color: #fff;
    width: 24px;
    height: 24px;
  }


}

.item {
  display: flex;
  align-items: center;
  overflow: hidden;

  &:last-child {
    justify-self: end;
    margin-left: auto;
    overflow: visible;
    flex-shrink: 0;
  }

}

.input {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #fff;
  margin-right: 16px;
  max-width: 250px;

  &::placeholder {
    color: #6e6e6e;
  }

  &.inputFill {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.subinput {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: var(--grey400);
  margin-right: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &::placeholder {
    color: #6e6e6e;
  }
}

.dayTotalTime {
  padding: 12px 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.16);
  margin-right: 16px;
  width: 100px;
  text-align: center;
}

.play {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #fff;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: transparent;
    background-color: #fff;
  }

  &:hover {
    &::after {
      transform: scale(1.1);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: #000;
  }
}