.container {
  position: relative;
  margin-left: auto;
}

.button {
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.1s ease-in;
  @media screen and (min-width: 1024px) {
    &:hover {
      background-color: var(--grey200);
    }
  }

  svg {
    width: 18px;
    height: 18px;
    color: var(--grey600);
  }
  span {
    width: 35px;
  }
  @media screen and (min-width: 1024px) {
    padding: 8px 12px;
  }
}

.dropdown {
  padding: 16px;
  background-color: #fff;
  z-index: 10;
  position: fixed;
  bottom: 72px;
  width: 100%;
  left: 0;
  @media screen and (min-width: 1024px) {
    position: absolute;
    top: calc(100% + 8px);
    
    bottom: inherit;
    width: 350px;
    
    border-radius: 16px;
    box-shadow: 0 12px 36px rgba(0,0,0,.16);
  }

  @media screen and (min-width: 1150px) {
    right: 0;
    left: inherit;
  }
}
