.timer {
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    position: fixed;
    bottom: 32px;
    right: 32px;
    padding: 13px;
    background-color: #000000;
    border-radius: 15px;
    z-index: 10;
    transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    svg {
      font-size: 30px;
      color: #fff;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
      transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
      z-index: -1;
      background-color: inherit;
    }
    &:hover {
      &::after {
        transform: scale(1.1);
      }
    }
  }
}

.timerContainer {
  right: 32px;
  position: fixed;
  z-index: 11;
  bottom: -24px;
  transition: opacity 0.25s ease, transform 0.25s ease, visibility 0.25s ease;
  visibility: hidden;
  opacity: 0;
  @media screen and (min-width: 768px) {
    bottom: -80px;
  }
}

.timerOpen:not(.timerOpenCurrent) {
  transform: translateY(-192px);
  opacity: 1;
  visibility: visible;
}

.timerOpenCurrent:not(.timerOpen) {
  transform: translateY(-192px);
  visibility: visible;
  opacity: 1;

  max-width: calc(100vw - 112px);
}

.timerCloseCurrent {
  transform: translateY(-192px);
  visibility: visible;
  opacity: 1;
}
