.container {
  background-color: #fff;
  padding: 32px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 274px;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09),
      0px 12px 24px rgba(0, 0, 0, 0.08);
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  color: #000;
  margin-bottom: 8px;
  @media screen and (min-width: 768px) {
    font-size: 22px;
  }
}

.description {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  color: #646464;
  margin-bottom: 24px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    font-size: 14px;
  }
}

.progress::-moz-progress-bar {
  background-color: #000;
  height: 8px;
  width: 100%;
}
.progress::-webkit-progress-value {
  background-color: #000;
  height: 8px;
  width: 100%;
}
.progress {
  display: flex;
  color: #000;
  margin-bottom: 10px;
  -webkit-appearance: none;
  height: 8px;
  appearance: none;
  width: 100%;
}

.progress::-webkit-progress-bar {
  background-color: var(--grey200);
  height: 8px;
  width: 100%;
}

.textsTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    color: #000;

    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
}

.red {
  background-color: var(--red);
  border-radius: 100px;
  padding: 4px 8px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
}
