.container {
  border: 1px solid var(--grey300);
  padding: 16px;
  transition: border-color 0.2s ease-in;
  &:hover {
    border-color: var(--grey400);
  }
}

.infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  .tag {
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    height: 21px;
    min-width: 21px;
    padding: 4px 8px;
    p {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: #000;
      display: -webkit-box;
      font-size: 10px;
      font-weight: 600;
      line-height: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.right {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  p {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    margin-right: 8px;
    display: flex;

    flex-direction: column;
  }
  svg {
    width: 17px;
    height: 17px;
    min-width: 17px;
    color: #aab1c7;
  }
}

.red {
  background-color: var(--red);
  border-radius: 100px;
  padding: 2px 8px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  // margin-left: 8px;
  width: fit-content;
}
