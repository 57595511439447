.header {
  display: flex;
  height: 64px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: #fff;

  @media screen and (min-width: 1024px) {
   background-color: transparent;
   height: auto;
  }
}

.box {
  padding: 24px;
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close {
  margin-left: auto;
}

.back {
  margin-right: 16px;
}

.customers {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 24px 0;
  li {
    button {
      padding: 12px 16px;
      border-radius: 100px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }
}

.customer {
  max-width: 100%;
  button {
    max-width: 100%;
    p {
      text-align: left;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 80px;
    height: 80px;
  }
}

.noProject {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--grey500);
  text-align: center;
}
